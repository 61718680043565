<script setup lang="ts">
import { FooterProps } from "./TheFooter.props"
import { safeWebsiteIconsSrcMap } from "./TheFooter.iconMapping"
import TheFooterBottom from "./TheFooterBottom.server.vue"
const { sendEventPhoneHelp } = useGA4SiteEvents()

withDefaults(defineProps<FooterProps>(), {})

const { height: windowHeight } = useWindowSize()
const pageHeight = useState<number>("wrapper-page-content-height-throttled")

const isLongPage = computed<boolean>(
  () => pageHeight.value > 3 * windowHeight.value
)
</script>

<template>
  <footer class="footer-wrapper grid-container z-20 text-white">
    <div
      v-if="isLongPage"
      class="
        back-to-top-container
        col-span-full
        bg-tiffany-main
        py-2
        text-center
      "
      @click="useScrollTop"
    >
      <UtilsButton
        class="custom-btn-back-to-top-button"
        theme="link-white"
        :text="$t('footer.textBackToTop')"
        order="last"
      >
        <template #icon>
          <UtilsIcon
            decoding="async"
            fetch-priority="low"
            name="ArrowUp.svg"
            color="white"
            class="h-5 w-5"
          />
        </template>
      </UtilsButton>
    </div>

    <div
      class="
        top-footer
        md:grid-container
        padded
        col-span-full
        bg-slate-main
        py-6
        md:py-10
      "
    >
      <div class="accordion-container flex flex-col gap-2 md:hidden">
        <!-- Accordion Item  -->
        <AppAccordion
          v-for="(menuItem, index_list) in linkList"
          :key="`accordion_menu_${index_list}`"
          class="custom-accordion-footer beaver-bold py-2"
          :text="menuItem.title"
          with-icons
          :is-open="null"
        >
          <template #icon-to-open>
            <UtilsIcon
              decoding="async"
              fetch-priority="low"
              name="ChevronDown.svg"
              color="white"
              class="h-6 w-6"
            />
          </template>

          <template #icon-to-close>
            <UtilsIcon
              decoding="async"
              fetch-priority="low"
              name="ChevronUp.svg"
              color="white"
              class="h-6 w-6"
            />
          </template>
          <template #body>
            <div class="my-2 flex flex-col items-start gap-4">
              <UtilsButton
                v-for="(listItem, index) in menuItem.list"
                :key="`accordio_link${index}`"
                class="custom-btn-mouse [&>span]:p-0 [&>span]:text-left"
                :text="listItem.label"
                :path="listItem.path"
                theme="ghost-white"
              />
              <a
                v-if="index_list === 2"
                class="
                  mouse
                  custom-btn-mouse
                  btn-link-white
                  whitespace-normal
                  p-0
                  text-left
                "
                :href="`javascript: Cookiebot.renew()`"
                >{{ $t("footer.cookieSetting") }}</a
              >
            </div>
          </template>
        </AppAccordion>
      </div>

      <!-- Desktop -->
      <div
        v-if="linkList?.length"
        class="col-span-8 hidden grid-cols-8 gap-6 md:grid"
      >
        <!-- First 3 columns -->
        <div class="col-span-6 grid grid-cols-6 gap-6">
          <div
            v-for="(link, index_list) in linkList.slice(0, 3)"
            :key="link.title"
            class="col-span-2"
            style="flex: 0 0 25%"
          >
            <p class="pig-bold mb-4">
              {{ link.title }}
            </p>
            <div class="flex flex-col items-start gap-4">
              <UtilsButton
                v-for="(listItem, index) in link.list"
                :key="`menuTopFarmacia_link${index}`"
                class="
                  custom-btn-mouse
                  [&>span]:whitespace-normal
                  [&>span]:p-0
                  [&>span]:text-left
                "
                :text="listItem.label"
                theme="ghost-white"
                :to="listItem.path"
              />
              <a
                v-if="index_list === 2"
                class="
                  mouse
                  custom-btn-mouse
                  btn-link-white
                  whitespace-normal
                  p-0
                  text-left
                "
                :href="`javascript: Cookiebot.renew()`"
                >{{ $t("footer.cookieSetting") }}</a
              >
            </div>
          </div>
        </div>
        <!-- Fourth column -->
        <div class="col-span-2 flex flex-col gap-8">
          <div v-for="link in linkList.slice(3)" :key="link.title">
            <p class="pig-bold mb-4">
              {{ link.title }}
            </p>
            <div class="flex flex-col items-start gap-4">
              <UtilsButton
                v-for="(listItem, index) in link.list"
                :key="`menuTopFarmacia_link${index}`"
                class="
                  custom-btn-mouse
                  [&>span]:whitespace-normal
                  [&>span]:p-0
                  [&>span]:text-left
                "
                :text="listItem.label"
                theme="ghost-white"
                :to="listItem.path"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Fifth column -->
      <div
        class="
          adress-and-contact-container
          mouse-medium
          mt-6
          flex flex-col
          gap-4
          md:col-span-2
          md:mt-0
        "
        id="address-and-contact-container"
      >
        <p class="pig-bold hidden no-underline md:block">
          {{ $t("footer.clientService") }}
        </p>

        <AppLink to="https://goo.gl/maps/pnTafV9bkLoqmHfG6">
          <div class="flex gap-2">
            <div class="shrink-0">
              <UtilsIcon
                decoding="async"
                fetch-priority="low"
                name="Pin.svg"
                color="white"
                class="h-4 w-4"
              />
            </div>
            <div>
              <p class="text-start underline hover:text-yellow-main">
                {{ $t("footer.address") }}
              </p>
            </div>
          </div>
        </AppLink>

        <div class="flex gap-2">
          <div class="shrink-0">
            <UtilsIcon
              decoding="async"
              fetch-priority="low"
              name="Phone.svg"
              color="white"
              class="h-4 w-4"
            />
          </div>
          <div>
            <a
              class="underline hover:text-yellow-main"
              :href="$t('general.supportPhone.link')"
              @click="sendEventPhoneHelp()"
            >
              {{ $t("general.supportPhone.label") }}
            </a>
            <p>{{ $t("footer.phoneHours") }}</p>
          </div>
        </div>

        <!-- Social -->
        <div
          class="
            mt-4
            flex flex-wrap
            items-center
            gap-5
            md:flex-col
            md:items-start
            md:gap-2
          "
        >
          <p class="mouse">{{ $t("footer.followUs") }}</p>

          <div class="flex flex-wrap gap-4">
            <AppLink to="https://www.facebook.com/TopFarmacia/">
              <UtilsIcon
                deconding="async"
                fetch-priority="low"
                name="FacebookRounded.svg"
                class="cursor-pointer"
              />
            </AppLink>

            <AppLink to="https://www.instagram.com/top_farmacia">
              <UtilsIcon
                deconding="async"
                fetch-priority="low"
                name="InstagramRounded.svg"
                class="cursor-pointer"
              />
            </AppLink>
          </div>
        </div>
      </div>

      <div class="safe-website-container mt-4 md:col-span-2 md:mt-0">
        <p class="beaver-bold md:pig-bold mb-2">
          {{ $t("footer.safeWebsite") }}
        </p>

        <div class="mb-6 flex flex-wrap gap-2">
          <AppLink
            v-for="icon in safeWebsiteIconsList"
            :key="`safeWebsite-${icon.name}`"
            class="inline-block h-10 cursor-pointer"
            :to="icon.path"
          >
            <UtilsIcon
              v-bind="safeWebsiteIconsSrcMap[icon.name]"
              class="mb-6 md:mb-4"
            />
          </AppLink>
        </div>

        <div>
          <p class="snail mb-4">
            {{ $t("footer.verifyOurEcommerce") }}
          </p>

          <div class="flex space-x-2 md:justify-between">
            <AppLink
              to="https://www.salute.gov.it/LogoCommercioElettronico/CercaSitoEComm?useMode=getDettaglio&VOLID=C015399"
            >
              <UtilsIcon
                deconding="async"
                fetch-priority="low"
                name="LegalWebsite.svg"
                class="h-[110px] max-w-full"
              />
            </AppLink>
            <AppLink
              to="https://www.salute.gov.it/LogoCommercioElettronicoVET/CercaSitoEComm?useMode=getDettaglio&VOLID=C015399&area=veterinari&id=5907&lingua=italiano&menu=vendite"
            >
              <UtilsIcon
                deconding="async"
                fetch-priority="low"
                name="LegalWebsiteVet.svg"
                class="h-[110px] max-w-full"
              />
            </AppLink>
          </div>
        </div>
      </div>
    </div>
    <TheFooterBottom v-if="footerIconsList" v-bind="footerIconsList" />
  </footer>
</template>
