export enum StripePosition {
  top = "TOP",
  bottom = "BOTTOM"
}

export type StripeProps = {
  stripePosition: string
  text: string
  backgroundColor?: string
  textColor?: string
}
