<script lang="ts" setup>
const route = useRoute()
const currentPath = computed(() => (route ? route.path : ""))
const isHomePage = computed(() => (currentPath.value !== "/" ? true : false))
//TODO : change logo will be managed on contentfull
const today = new Date()

const CHANGE_LOGO_DATE = {
  day: 14, //from 1 to 31
  month: 1 //from 0 to 11
}
const TEST_DELAY = 60000
const DEFAULT_LOGO = "LogoTopFarmaciaOnline.svg"
const CHANGE_LOGO = "LogoLove1.svg"

const setLogo = (date: Date) =>
  date.getMonth() === CHANGE_LOGO_DATE.month &&
  date.getDate() === CHANGE_LOGO_DATE.day
    ? CHANGE_LOGO
    : DEFAULT_LOGO

const interval = ref()

const logoName = ref(setLogo(today))

onMounted(() => {
  if (
    today.getMonth() <= CHANGE_LOGO_DATE.month &&
    today.getDate() < CHANGE_LOGO_DATE.day
  ) {
    interval.value = setInterval(() => {
      const date = new Date()
      logoName.value = setLogo(date)
    }, TEST_DELAY)
  }
})

onUnmounted(() => {
  clearInterval(interval.value)
})
</script>

<template>
  <AppLink to="/">
    <h1 v-if="!isHomePage">
      <UtilsIcon
        fetch-priority="high"
        :preload="true"
        :name="logoName"
        class="h-11 w-40 flex-shrink-0 cursor-pointer"
        :altText="$t('general.topFarmaciaBest')"
        :titleText="$t('general.topFarmaciaBest')"
      />
      <span class="hidden">{{ $t("general.topFarmacia") }}</span>
    </h1>
    <UtilsIcon
      v-else
      fetch-priority="high"
      :preload="true"
      :name="logoName"
      class="h-11 w-40 flex-shrink-0 cursor-pointer"
      :altText="$t('general.topFarmaciaBest')"
      :titleText="$t('general.topFarmaciaBest')"
    />
  </AppLink>
</template>
