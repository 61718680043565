<script setup lang="ts">
import { StripePosition, StripeProps } from "./Stripe.props"
import { pushHeaderClickCtaEvent } from "~/utils/analytics/clickCta"

const props = withDefaults(defineProps<StripeProps>(), {
  backgroundColor: "#000000",
  textColor: "#ffffff"
})

const getLink = () => {
  return document.querySelector(
    `.${props.stripePosition}-header .markdown__link-underlined.markdown__link-mouse-medium > a`
  )
}

const pushGtm = (evt: Event) => {
  const text = (evt.target as HTMLAnchorElement)?.text
  pushHeaderClickCtaEvent(text)
}

onMounted(() => {
  const link = getLink()
  link?.addEventListener("click", (evt) => pushGtm(evt))
})

onUnmounted(() => {
  const link = getLink()
  link?.removeEventListener("click", (evt) => pushGtm(evt))
})
</script>

<template>
  <div
    class="px-2 text-center"
    :class="[
      stripePosition === StripePosition.top ? 'py-1' : 'py-2',
      `${stripePosition}-header`
    ]"
    :style="{
      backgroundColor: backgroundColor
    }"
  >
    <UtilsMarkdown
      :content="text"
      :style="{
        color: textColor
      }"
      class="
        mouse-bold
        markdown__link-underlined markdown__link-mouse-medium
        line-clamp-2
      "
    />
  </div>
</template>
